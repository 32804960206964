import React, {useEffect, useRef} from "react";
import {Link} from "gatsby";
import {TimelineLite} from "gsap";
// import { TextScramble } from '@a7sc11u/scramble';
// import {Helmet} from "react-helmet";


import "./promo.css";

const Promo = () => {
  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const buttonRef = useRef(null);
  const tl = new TimelineLite({paused: true});

  useEffect(() => {
    tl.to(titleRef.current, 0.25, {y: 0, opacity: 1})
      .to(paragraphRef.current, 0.25, {y: 0, opacity: 1}, "-=0.1")
      .to(buttonRef.current, 0.25, {y: 0, opacity: 1}, "-=0.1")
      .play();
  });

  return (
    <section className="promo cardd card1">
      <h1 ref={titleRef} className="promo__title h1 slide-down">
      I&rsquo;m a <span className="promo__color1">CS Researcher</span> 👨‍💻, <span className="promo__color2">Tech Entrepreneur</span> 🛠️, and <span className="promo__color3">EECS student</span> ⚡  
      </h1>
      {/* // return TextScramble component */}
      
        <p ref={paragraphRef} className="promo__subtitle slide-down z-10 content">
         I adore intersecting <span className="reveal-text waveflow">Web</span> and <span className="reveal-text waveflow">Machine Learning</span> to craft solutions for complex real-world challenges. The love for new skills and desire to create something which can directly influence people, keeps me interested! 🚀
        </p>
      

      {/* <Helmet> */}
      {/* <div className="invisible"> */}
      {/* <img src="//clustrmaps.com/map_v2.png?cl=ffffff&w=a&t=n&d=gjtVi_JOFKsKAnbH8PUExsZtCCkLLTGzu7AdoniE1RI&co=2b4f68" /> */}
      {/* <script type="text/javascript" id="clstr_globe" src="//clustrmaps.com/globe.js?d=gjtVi_JOFKsKAnbH8PUExsZtCCkLLTGzu7AdoniE1RI"></script> */}
      {/* </div> */}
      {/* </Helmet> */}
      

      {/* <p className={`promo__subtitle slide-down`} ref={paragraphRef}>
        I&nbsp;don&rsquo;t like to&nbsp;define myself by&nbsp;the work
        I&rsquo;ve done. I&nbsp;define myself by&nbsp;the work&nbsp;I want
        to&nbsp;do.
      </p> */}
      
      <div ref={buttonRef} className={`slide-down`}>
        <Link className={`promo__button button`} to={`/contacts`}>
          hire me
        </Link>
      </div>
    </section>
  );
};

export default Promo;
